// Bust cookie with new country code https://www.epochconverter.com
export const COUNTRY_CODE_COOKIE_KEY = 'countryCode-1680808740';

interface DiscordWebhook {
  id?: string;
  token?: string;
}

export const DISCORD_WEBHOOK: DiscordWebhook = {
  id: process.env.DISCORD_WEBHOOK_FEEDBACK_ID,
  token: process.env.DISCORD_WEBHOOK_FEEDBACK_TOKEN,
}

export const GOOGLE_MAPS_STATIC_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_STATIC_API_KEY;

export interface OpenAIPayload {
  model: string;
  max_tokens: number;
  stream: boolean;
  n: number;
}

interface OpenAIConfig {
  api_key?: string;
  org?: string;
  settings: OpenAIPayload
}

export const OPEN_AI: OpenAIConfig = {
  api_key: process.env.OPENAI_API_KEY,
  org: process.env.OPEN_AI_ORG,
  settings: {
    model: 'gpt-3.5-turbo',
    max_tokens: 1000,
    stream: true,
    n: 1,
  }
}

export const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

export const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
